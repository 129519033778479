<template>
    <section class="content-wrapper">
        <div class="row mt-20">
            <div class="col-12">
                <div class="box box-solid box-primary">
                    <div class="box-header">
                        <h4 class="box-subtitle text-white">#{{pageData.order ? pageData.order.orderID : ''}}</h4>
                    </div>
                    <div class="box-body">
                        <form @submit.prevent="onSubmit" class="form-horizontal form-element pl-30 pr-30">
                            <div class="row">
                                <h3>Order Information</h3>
                                <p class="text-danger" v-if="error">{{errorMessage}}</p>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select v-model="orderStatus" @change="onStatusValueChange" class="form-control">
                                            <option value="pending">Pending</option>
                                            <option v-if="pageData.order && !pageData.order.user_subscription" value="unpaid">Unpaid</option>
                                            <option v-if="pageData.order && !pageData.order.user_subscription"  value="paid">Paid</option>
                                            <option value="processing">Processing</option>
                                            <option value="in-transit">In Transit</option>
                                            <option value="delivered">Delivered</option>
                                            <option value="cancelled">Cancelled</option>
                                        </select>
                                        <p class="error" v-if="orderStatusError">{{orderStatusErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Comment</label>
                                        <div class="controls">
                                            <textarea cols="4" rows="2" v-model="orderStatusComment" class="form-control" placeholder="Comment"></textarea>
                                        </div>
                                        <p class="error" v-if="orderStatusCommentError">{{orderStatusCommentErrorMessage}}</p>
                                    </div>
                                    <div v-if="pageData && pageData.order" class="form-group">
                                        <label>Pickup Rider</label>
                                        <select v-model="selectedPickupRiderUserID" @change="onPickupRiderChange" class="form-control">
                                            <option v-for="rider in pageData.order.pickupRiders" :key="rider.id" :value="rider.user.id">{{rider.user.first_name}} {{rider.user.last_name}}</option>
                                        </select>
                                        <!-- <p class="error" v-if="orderStatusCommentError">{{orderStatusCommentErrorMessage}}</p> -->
                                    </div>
                                    <div v-if="orderStatus=='cancelled'" class="form-group">
                                        <label>Amount To Charge Customer</label>
                                        <div class="controls">
                                            <input v-model="cancellationCharge" type="number" class="form-control"  placeholder="Cancellation Charge"/>
                                            <p class="error" v-if="cancellationChargeError">{{cancellationChargeErrorMessage}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showUnpaidData && pageData && pageData.order && !pageData.order.user_subscription" class="col-md-4">
                                    <div class="form-group">
                                        <label>Item Weight</label>
                                        <select v-model="selectedWeight" @change="onItemWeightChange" class="form-control">
                                            <option value="">Select Weight</option>
                                            <option v-for="weight in supportedWeightList" :key="weight.id" :value="weight">{{weight.weight}}</option>
                                        </select>
                                        <p class="error" v-if="selectedWeightError">{{selectedWeightErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Delivery Cost</label>
                                        <input v-model="selectedWeightPrice" type="number" class="form-control"  placeholder="Price"/>
                                        <p class="error" v-if="selectedWeightPriceError">{{selectedWeightPriceErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Extra Charge</label>
                                        <input v-model="orderExtraCharge" type="number" class="form-control"  placeholder="Extra charge on order"/>
                                        <p class="error" v-if="orderExtraChargeError">{{orderExtraChargeErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Total Cost</label>
                                        <input v-model="orderTotalCost" type="number" class="form-control"  placeholder="Price" disabled/>
                                    </div>
                                    <div class="form-group">
                                        <label>Extra Charge Comment</label>
                                        <input v-model="orderExtraChargeComment" type="text" class="form-control"  placeholder="Comment for extra charge on this order"/>
                                    </div>
                                </div>
                                <div v-if="showPaidData  && pageData && pageData.order && !pageData.order.user_subscription" class="col-md-4">
                                    <div class="form-group">
                                        <label>Who is paying?</label>
                                        <select v-model="paidBy" class="form-control">
                                            <option value="customer">Customer</option>
                                            <option value="admin">Company</option>
                                        </select>
                                    </div>
                                    <div v-if="paidBy=='customer'" class="form-group">
                                        <label>Mode of Payment</label>
                                        <select v-model="paymentMethod" class="form-control">
                                            <option value="wallet">Wallet</option>
                                            <option value="cash">Cash</option>
                                            <option value="transfer">Transfer</option>
                                            <option value="paystack">Paystack</option>
                                            <option value="flutterwave">Flutterwave</option>
                                        </select>
                                    </div>
                                    <div v-if="paidBy=='customer' && paymentMethod!='wallet'" class="form-group">
                                        <label>Receipt No/Reference</label>
                                        <input v-model="paymentReference" type="text" class="form-control"  placeholder="Reference/No"/>
                                        <p class="error" v-if="paymentReferenceError">{{paymentReferenceErrorMessage}}</p>
                                    </div>
                                    <div v-if="pageData && pageData.order" class="form-group">
                                        <label>Delivery Rider</label>
                                        <select v-model="selectedDeliveryRiderUserID" @change="onDeliveryRiderChange" class="form-control">
                                            <option v-for="rider in pageData.order.deliveryRiders" :key="rider.id" :value="rider.user.id">{{rider.user.first_name}} {{rider.user.last_name}}</option>
                                        </select>
                                        <!-- <p class="error" v-if="orderStatusCommentError">{{orderStatusCommentErrorMessage}}</p> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-6 mt-60">
                                    <button v-if="isRoleAllowedDelete" @click="updateOrder" class="btn btn-primary btn-large">Update Order<span v-if="loading" class="spinner-border text-white"></span></button>
                                    <p class="text-danger" v-if="error">{{errorMessage}}</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="box box-solid box-primary">
                    <div class="box-header">
                        <h4 class="box-subtitle text-white float-left">Order</h4>
                        <button class="btn btn-success btn-md float-right" @click="printOrder">Print</button>
                    </div>
                    <section v-if="pageData.order" class="box-body printableOrder">
                        <form @submit.prevent="onSubmit" class="form-horizontal form-element mb-20 p-30">
                            <div class="row">
                                <div class="col-6 col-md-6 col-lg-6 pr-60">
                                    <h3>Pickup Details</h3>
                                    <div class="form-group">
                                        <label class="text-info">Pickup Name</label>
                                        <p>{{pageData.order.pickup_name}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup Phone</label>
                                        <p>{{pageData.order.pickup_phone}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup Address</label>
                                        <p>{{pageData.order.pickup_address}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup Country</label>
                                        <p>{{pageData.order.pickup_country ? pageData.order.pickup_country.name : ''}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup State</label>
                                        <p>{{pageData.order.pickup_state ? pageData.order.pickup_state.name : ''}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup City</label>
                                        <p>{{pageData.order.pickup_city ? pageData.order.pickup_city.name : ''}}</p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-6">
                                    <h3>Delivery Details</h3>
                                    <div class="form-group">
                                        <label class="text-info">Delivery Name</label>
                                        <p>{{pageData.order.delivery_name}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery Phone</label>
                                        <p>{{pageData.order.delivery_phone}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery Address</label>
                                        <p>{{pageData.order.delivery_address}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery Country</label>
                                        <p>{{pageData.order.delivery_country ? pageData.order.delivery_country.name : ''}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery State</label>
                                        <p>{{pageData.order.delivery_state ? pageData.order.delivery_state.name : ''}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery City</label>
                                        <p>{{pageData.order.delivery_city ? pageData.order.delivery_city.name : ''}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="showPriceEstimate" class="row mb-30 mt-30">
                                <div class="col-12 col-lg-12">
                                    <p class="text-center text-info">
                                        {{ computeEstimatedPrice }}
                                        <button v-if="!isNaN(estimatedPrice)" type="button" class="btn btn-info btn-xs" data-toggle="modal" data-target="#price-detail">View More</button>
                                        <PriceDetail :priceList="estimatedPriceList"/>
                                    </p>
                                </div>
                            </div> -->
                            <div class="row mb-30 mt-30">
                                <div class="col-6 col-md-6 col-lg-6 pr-60">
                                    <div class="form-group">
                                        <label class="text-info">Pickup Extra Info</label>
                                        <p>{{pageData.order.pickup_extra_information ? pageData.order.pickup_extra_information : 'None'}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Item Description</label>
                                        <p>{{pageData.order.item_description}}</p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label class="text-info">Delivery Extra Information</label>
                                        <p>{{pageData.order.delivery_extra_information ? pageData.order.delivery_extra_information : 'None'}}</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
        
    </section>
</template>

<script>
import { repository } from '../../presenter';
import { setTimeout } from 'timers';
export default {
    props: ['orderData'],
    data(){
        return{

            pageData: {},

            supportedWeightList: [],

            selectedPickupRiderUserID: "",
            selectedDeliveryRiderUserID: "",

            showUnpaidData: false,
            showPaidData: false,
            orderStatus: 'pending',
            orderStatusError: false,
            orderStatusErrorMessage: '',
            orderStatusComment: '',
            orderStatusCommentError: false,
            orderStatusCommentErrorMessage: '',
            selectedWeight: null,
            selectedWeightError: false,
            selectedWeightErrorMessage: '',
            selectedWeightPrice: 0,
            selectedWeightPriceError: false,
            selectedWeightPriceErrorMessage: '',
            orderExtraCharge: 0,
            orderExtraChargeError: false,
            orderExtraChargeErrorMessage: '',
            orderExtraChargeComment: '',
            orderTotalCost: '',
            paidBy: 'admin',
            paymentMethod: 'cash',
            paymentReference: '',
            paymentReferenceError: false,
            paymentReferenceErrorMessage: '',
            cancellationCharge: 0,
            cancellationChargeError: false,
            cancellationChargeErrorMessage: '',


        
            // customer : this.$route.params.customerData,
            loading: false,
            error: false,
            errorMessage: '',
            publicPath: process.env.BASE_URL,
            
        }
    },
    watch: {
        selectedWeightPrice: function(val) {
            this.onCalculateTotalCost();
        },
        orderExtraCharge: function(val) {
            this.onCalculateTotalCost();
        },
    },
    mounted() {
        const sweetAlert = document.createElement('script')
        sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
        document.head.appendChild(sweetAlert)

        const printarea = document.createElement('script')
        printarea.setAttribute('src', `${this.publicPath}assets/styles/vendor_plugins/JqueryPrintArea/demo/jquery.PrintArea.js`)
        document.head.appendChild(printarea)

        if (!this.$route.params.orderID){
            this.$router.replace('/vieworders');
            return;
        }

        this.loadSupportedWeightList();
        this.loadOrderData()

    },

    computed: {
        isRoleAllowedDelete(){
            return repository.adminIsLord()
        },
    },

    methods: {

        async loadOrderData(){
            let response = await repository.order.loadOrder(this.$route.params.orderID)
            console.log("response in full view order is ", response);
            if (response.success) {
                this.pageData = response.data;
                this.setDynamicData()
                return;
            }
        },
        setDynamicData() {
            this.orderStatus = this.pageData.order.status;
            this.cancellationCharge = this.pageData.order.cancellation_charge
            this.onStatusValueChange();
            this.selectedPickupRiderUserID = this.pageData.order.assigned_pickup_rider_user_id
            this.selectedDeliveryRiderUserID = this.pageData.order.assigned_delivery_rider_user_id
            console.log("selected pickup ruider user id is ", this.selectedPickupRiderID)
            this.orderExtraCharge = this.pageData.order.delivery_extra_charges
            this.supportedWeightList.forEach(data => {
                if (data.id==this.pageData.order.item_weight_id) {
                    this.selectedWeight=data;
                    this.onItemWeightChange();
                    this.selectedWeightPrice = this.pageData.order.delivery_cost
                    return;
                }
            });
            this.orderExtraChargeComment = this.pageData.order.delivery_extra_charges_comment
            this.paidBy = this.pageData.order.transaction && this.pageData.order.transaction.payment_gateway=='admin' ? 'admin' : 'customer'
            this.paymentMethod = (!this.pageData.order.transaction || !this.pageData.order.transaction.payment_gateway) ? 'cash' : this.pageData.order.transaction.payment_gateway;
            this.paymentReference = this.pageData.order.transaction && this.pageData.order.transaction.payment_reference
        },

        async updateOrder() {
            if (this.loading) return
            this.resetData()
            const updateOrderValidation = this.validateUpdateOrderData()
            if (!updateOrderValidation.success) return
            this.loading = true
            const data = {
                orderID: this.pageData.order.orderID,
                userID: this.pageData.order.user_id,
                status: this.orderStatus,
                statusComment: this.orderStatusComment,
                weightID: this.pageData.order.user_subscription ? null : (this.selectedWeight ? this.selectedWeight.id : ''),
                deliveryCost: this.pageData.order.user_subscription ? 0 : this.selectedWeightPrice,
                extraCharge: this.pageData.order.user_subscription ? 0 : this.orderExtraCharge,
                extraChargeComment: this.pageData.order.user_subscription ? null : this.orderExtraChargeComment,
                paidBy: this.pageData.order.user_subscription ? null : this.paidBy,
                paymentMethod: this.pageData.order.user_subscription ? null : this.paymentMethod,
                paymentReference: this.pageData.order.user_subscription ? null : this.paymentReference,
                cancellationCharge: this.cancellationCharge,
                pickupRiderUserId: this.selectedPickupRiderUserID,
                deliveryRiderUserId: this.selectedDeliveryRiderUserID,
            }
            console.log('data is ', data)
            const response = await repository.order.updateOrder(data);
            this.loading = false
            console.log('response is ', response)
            if (response.success) {
                this.showSuccessMessage('Order Updated');
                return
            }
            this.showErrorMessage(response.data)
        },

















        validateUpdateOrderData() {
            const returnValue = new Object()
            if (this.showUnpaidData && this.pageData.order && !this.pageData.order.user_subscription){
                if (!this.selectedWeight){
                    this.selectedWeightError=true;
                    this.selectedWeightErrorMessage='Select Item Weight';
                    returnValue.success=false;
                    return returnValue;
                }
                if (this.selectedWeightPrice < 0){
                    this.selectedWeightPriceError=true;
                    this.selectedWeightPriceErrorMessage='Price is invalid';
                    returnValue.success=false;
                    return returnValue;
                }
                if (this.orderExtraCharge < 0){
                    this.selectedWeightError=true;
                    this.selectedWeightErrorMessage='Extra charge is invalid';
                    returnValue.success=false;
                    return returnValue;
                }
            }
            if (this.showPaidData && this.pageData.order && !this.pageData.order.user_subscription){
                if (this.paidBy=='customer' && this.paymentMethod!='wallet' && !this.paymentReference){
                    this.paymentReferenceError=true;
                    this.paymentReferenceErrorMessage='Enter a payment reference';
                    returnValue.success=false;
                    return returnValue;
                }
            }
            if (this.orderStatus=='cancelled' && this.cancellationCharge<0){
                this.cancellationChargeError=true;
                this.cancellationChargeErrorMessage='Cancellation fee not valid';
                returnValue.success=false;
                return returnValue;
            }
            
            returnValue.success = true
            return returnValue
        },
        resetData() {
            this.orderStatusError=false;
            this.paymentReferenceError=false;
            this.orderStatusCommentError=false;
            this.selectedWeightError=false;
            this.selectedWeightPriceError=false;
            this.orderExtraChargeError=false;
            
            this.error = false
            this.errorMessage = ''
        },
        showSuccessMessage (message) {
            swal(message, "", "success")
        },
        showErrorMessage (message) {
            this.error = true
            this.errorMessage = message
        },






        onStatusValueChange(){
            switch(this.orderStatus) {
                case 'pending':
                    this.showUnpaidData=false;
                    this.showPaidData=false;
                    break;
                case 'unpaid':
                    this.showUnpaidData=true;
                    this.showPaidData=false;
                    break;
                case 'paid':
                case 'processing':
                    this.showUnpaidData=true;
                    this.showPaidData=true;
                    break;
                case 'in-transit':
                case 'delivered':
                    this.showUnpaidData=true;
                    this.showPaidData=true;
                    break;
                case 'cancelled':
                    this.showUnpaidData=false;
                    this.showPaidData=false;
                    break;
                default:
                    this.showUnpaidData=true;
                    this.showPaidData=true;  
            }
        },
        onItemWeightChange(){
            if (this.pageData.priceData){
                for(let priceData of this.pageData.priceData){
                    if (this.selectedWeight.id==priceData.supported_weight_id){
                        this.selectedWeightPrice = priceData.price;
                        this.onCalculateTotalCost();
                        break;
                    }
                    this.selectedWeightPrice = ""
                }
            }
            
            
            
        },

        onCalculateTotalCost() {
            this.orderTotalCost = parseFloat(this.selectedWeightPrice) + parseFloat(this.orderExtraCharge);
        },

        async loadSupportedWeightList() {
            const weightListResponse = await repository.regions.fetchSupportedWeightList();
            if (weightListResponse.success){
                this.supportedWeightList = weightListResponse.data;
                return;
            }
        },

        printOrder() {
            var mode = 'iframe'; //popup
            var close = mode == "popup";
            var options = {
                mode: mode,
                popClose: close
            };
            $("section.printableOrder").printArea(options);
        },
        onSubmit() {

        },

        
    }
}
</script>


<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

